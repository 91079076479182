import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import { StyledContainer } from '../layouts/styles';
import Metadata from '../components/Metadata';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const StyledIcon = styled(FontAwesomeIcon)`
  display: block;
  color: var(--color-primary);

  @media (min-width: 576px) {
    margin-right: 0.5rem;
  }
`;

const StyledHeadingImage = styled.div`
  h1 {
    margin-bottom: 2rem;
  }

  @media (min-width: 920px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  gap: 3rem;
`;

const StyledContactInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;

  p {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    color: black;

    &:hover {
      color: var(--color-primary);
    }
  }

  @media (min-width: 576px) {
    flex-direction: row;
  }
`;

function Erwachsene() {
  return (
    <>
      <Metadata title='(Junge) Erwachsene' />
      <StyledContainer>
        <Section>
          <StyledHeadingImage>
            <h1>(Junge) Erwachsene</h1>
            <StaticImage
              src='../images/Erwachsene_Seite.png'
              alt='Pinboard Pfade'
            ></StaticImage>
          </StyledHeadingImage>
        </Section>
        <Section>
          <p>
            Suchen Sie Unterstützung in einem oder mehreren der aufgeführten
            Bereiche, könnte unser Angebot Ihnen weiterhelfen:
          </p>
          <ul style={{ marginBottom: '2rem' }}>
            <li>
              Verarbeitung der Diagnose
              <ul>
                <li>Was hat sich dadurch für mich verändert?</li>
                <li>Erfahre ich Hürden durch den Autismus?</li>
                <li>Wie gehe ich mit diesen Herausforderungen um?</li>
                <li>Wie gehe ich in meinem Umfeld mit der Diagnose um?</li>
                <li>
                  Bewerte ich Ereignisse in der Vergangenheit und bestimmte
                  Eigenschaften nun anders?
                </li>
              </ul>
            </li>
            <li>(An-)Erkennen von eigenen Stärken und Ressourcen </li>
            <li>
              Umgang mit sozialen Schwierigkeiten und Erarbeitung von Strategien
              zur Bewältigung der Herausforderungen
            </li>
            <li>
              Erarbeitung oder Erweiterung von kommunikativen und sozialen
              Kompetenzen
            </li>
            <li>Selbständigkeitsentwicklung</li>
            <li>Selbstorganisation des Alltag und Schule/Studium/Beruf</li>
            <li>Erarbeitung von Strategien zur Konfliktbewältigung</li>
            <li>Erarbeitung von Strategien zur Stressbewältigung</li>
            <li>
              weitere individuelle und relevante Themen, die unter die Leistung
              der Autismustherapie fallen
            </li>
          </ul>
          <p>
            Die konkreten Inhalte, Ziele und den Umfang unseres therapeutischen
            Angebots legen wir natürlich gemeinsam mit Ihnen fest.
          </p>
          <p>Haben Sie Interesse an unserem Angebot oder noch Fragen?</p>
          <StyledContactInfo>
            <a href='mailto:info@lernen-rueckenwind.de'>
              <StyledIcon icon={faEnvelope} size='2x' />
            </a>
            <p>
              <a href='mailto:info@lernen-rueckenwind.de'>
                info@lernen-rueckenwind.de
              </a>
            </p>
          </StyledContactInfo>
          <p>
            Vereinbaren Sie gerne einen Termin zum unverbindlichen Kennenlernen.
          </p>
        </Section>
      </StyledContainer>
    </>
  );
}

export default Erwachsene;
